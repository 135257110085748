import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../../context/UserContext";
import Axios from "axios";
import ErrorNotice from "../misc/ErrorNotice";

export default function UserFilter() {
    const [displayName, setDisplayname] = useState();
    const [role, setRole] = useState();
    const [status, setStatus] = useState();
    const [password, setPassword] = useState();
    const [passwordCheck, setPasswordCheck] = useState();
    const [error, setError] = useState();

    const { userData } = useContext(UserContext);
    const history = useNavigate();
    const params = new URLSearchParams(window.location.search);
    const id = params.get('id');

    useEffect(() => {
        if (localStorage.getItem("auth-token") === '') {
            history('/admin/login');
        }

        async function fetchData() {
            console.log('Este es el id', id);
            const userRes = await Axios.get(`${process.env.REACT_APP_BACK_DOMAIN}/users/findone/${id}`);
            setDisplayname(userRes.data.displayName);
            setRole(userRes.data.role);
            setStatus(userRes.data.status);
        }
        fetchData();
    }, []);

    const submit = async (e) => {
        e.preventDefault();
        try {
            if (userData.user && id) {
                const modificator = userData.user.displayName;
                const newDataUser = password && passwordCheck ?
                    { id, displayName, password, passwordCheck, role, status, modificator } :
                    { id, displayName, role, status, modificator };
                await Axios.put(`${process.env.REACT_APP_BACK_DOMAIN}/users/update`, newDataUser);
                alert("Usuario actualizado correctamente");
                history('/admin/register');
            }
        } catch (err) {
            err.response.data.msg && setError(err.response.data.msg);
        }
    };


    return (
        <div className="page">
            <div className="container">
                <h2>Actualizar Usuario</h2>
                {error && (
                    <ErrorNotice message={error} clearError={() => setError(undefined)} />
                )}
                <form className="form" onSubmit={submit}>
                    <div className="row">
                        <div className="col s12">
                            Nombre de Usuario:
                            <div className="input-field inline">
                                <input
                                    id="display_name"
                                    name="displayname"
                                    onChange={(e) => setDisplayname(e.target.value)}
                                    type="text"
                                    className="validate"
                                    value={displayName}
                                />
                            </div>

                            {
                                userData.user && userData.user.role === 'administrador' ?
                                    <>
                                        <label htmlFor="role">Rol: </label>
                                        <select name="role" id="role" value={role} onChange={(e) => setRole(e.target.value)}>
                                            <option>--- Seleccione una opción ---</option>
                                            <option value="administrador">Administrador</option>
                                            <option value="consulta">Consulta</option>
                                        </select>
                                        <br></br>
                                        <br></br>
                                        <label htmlFor="status">Estado: </label>
                                        <select name="status" id="status" value={status} onChange={(e) => setStatus(e.target.value)}>
                                            <option>--- Seleccione una opción ---</option>
                                            <option value={true}>Activo</option>
                                            <option value={false}>Inactivo</option>
                                        </select>
                                        <br></br>
                                        <br></br>
                                    </> :
                                    ''
                            }

                            <label htmlFor="pwd">Password:</label>
                            <input
                                type="password" id="pwd" name="pwd"
                                minLength="5" value={password} onChange={(e) => setPassword(e.target.value)} />
                            <br></br><br></br>

                            <label htmlFor="check_pwd">Verificar Password:</label>
                            <input
                                type="password" id="check_pwd" name="check_pwd"
                                minLength="5" value={passwordCheck} onChange={(e) => setPasswordCheck(e.target.value)} />
                            <br></br><br></br>

                            <input type="submit" value="Actualizar" />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}