import React, { useEffect, useState, /*useContext*/ } from "react";
import { useNavigate } from "react-router-dom";
// import UserContext from "../../context/UserContext";
import TableToExcel from 'react-html-table-to-excel';

// import Axios from "axios";
import ErrorNotice from "../misc/ErrorNotice";

export const Records = () => {
    const [date1, setDate1] = useState('');
    const [date2, setDate2] = useState('');
    const [destination, setDestination] = useState('');
    const [records, setRecords] = useState([]);
    const [error, setError] = useState();

    // const { userData } = useContext(UserContext);
    const history = useNavigate();

    useEffect(() => {
        if (localStorage.getItem("auth-token") === '') history.push('/admin/login');
    })

    const getCurrentDate = (date) => {
        return new Date(Date.parse(date) - 1000 * 60 * 60 * 5).toISOString();
    }

    const getRecords = async (e) => {
        try {

            const dateIso1 = getCurrentDate(new Date(date1));
            const dateIso2 = getCurrentDate(new Date(date2));

            if (dateIso1 && dateIso2 && destination) {
                fetch(`${process.env.REACT_APP_BACK_DOMAIN}/${(destination === '4' || destination === '5') ? 'academic' : 'plan'}/date/${destination}/${dateIso1}/${dateIso2}`, {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                })
                    .then((res) => res.json())
                    .then((data) => {
                        setRecords(data);
                    });
            }
            if (e) {
                e.preventDefault();
            }
        } catch (err) {
            err.response.data.msg && setError(err.response.data.msg);
        }
    };

    const getTable = (destination, records) => {
        if (destination === '4') {
            return (
                <table className="striped" id="recordResults">
                    <thead>
                        <tr>
                            <th>Num</th>
                            <th>Nombre</th>
                            <th>Email</th>
                            <th>Imagen</th>
                            <th>Creación</th>
                            <th>Celular</th>
                            <th>Teléfono</th>
                            <th>País</th>
                            <th>Departamento</th>
                            <th>Ciudad</th>
                            <th>Carrera</th>
                            <th>Semestre</th>
                        </tr>
                    </thead>
                    <tbody>
                        {records.map((record, index) => {
                            return (
                                <tr key={record._id}>
                                    <td>{index + 1}</td>
                                    <td>
                                        <p style={{ width: "100px", overflowWrap: "break-word" }}>
                                            {`${record.name} - ${record.surname}`}
                                        </p>
                                    </td>
                                    <td>
                                        <p style={{ width: "200px", overflowWrap: "break-word" }}>
                                            {`${record.email}`}
                                        </p>
                                    </td>
                                    <td>
                                        <p style={{ width: "200px", overflowWrap: "break-word" }}>
                                            <a href={`${record.urlImage}`} target='_blank'>{`${record.urlImage}`}</a>
                                        </p>
                                    </td>
                                    <td>
                                        <p style={{ width: "200px", overflowWrap: "break-word" }}>
                                            {`${record.created}`}
                                        </p>
                                    </td>
                                    <td>
                                        <p style={{ width: "100px", overflowWrap: "break-word" }}>
                                            {`${record.cellphone}`}
                                        </p>
                                    </td>
                                    <td>
                                        <p style={{ width: "100px", overflowWrap: "break-word" }}>
                                            {`${record.phone}`}
                                        </p>
                                    </td>
                                    <td>
                                        <p style={{ width: "100px", overflowWrap: "break-word" }}>
                                            {`${record.country}`}
                                        </p>
                                    </td>
                                    <td>
                                        <p style={{ width: "100px", overflowWrap: "break-word" }}>
                                            {`${record.department}`}
                                        </p>
                                    </td>
                                    <td>
                                        <p style={{ width: "100px", overflowWrap: "break-word" }}>
                                            {`${record.city}`}
                                        </p>
                                    </td>
                                    <td>
                                        <p style={{ width: "100px", overflowWrap: "break-word" }}>
                                            {`${record.carrer}`}
                                        </p>
                                    </td>
                                    <td>
                                        <p style={{ width: "100px", overflowWrap: "break-word" }}>
                                            {`${record.semester}`}
                                        </p>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            );
        } else if (destination === '5') {
            return (
                <table className="striped" id="recordResults">
                    <thead>
                        <tr>
                            <th>Num</th>
                            <th>Nombre</th>
                            <th>Email</th>
                            <th>Imagen</th>
                            <th>Creación</th>
                            <th>Celular</th>
                            <th>Teléfono</th>
                            <th>País</th>
                            <th>Departamento</th>
                            <th>Ciudad</th>
                            <th>Tipo de Institución</th>
                        </tr>
                    </thead>
                    <tbody>
                        {records.map((record, index) => {
                            return (
                                <tr key={record._id}>
                                    <td>{index + 1}</td>
                                    <td>
                                        <p style={{ width: "100px", overflowWrap: "break-word" }}>
                                            {`${record.name} - ${record.surname}`}
                                        </p>
                                    </td>
                                    <td>
                                        <p style={{ width: "200px", overflowWrap: "break-word" }}>
                                            {`${record.email}`}
                                        </p>
                                    </td>
                                    <td>
                                        <p style={{ width: "200px", overflowWrap: "break-word" }}>
                                            <a href={`${record.urlImage}`} target='_blank'>{`${record.urlImage}`}</a>
                                        </p>
                                    </td>
                                    <td>
                                        <p style={{ width: "200px", overflowWrap: "break-word" }}>
                                            {`${record.created}`}
                                        </p>
                                    </td>
                                    <td>
                                        <p style={{ width: "100px", overflowWrap: "break-word" }}>
                                            {`${record.cellphone}`}
                                        </p>
                                    </td>
                                    <td>
                                        <p style={{ width: "100px", overflowWrap: "break-word" }}>
                                            {`${record.phone}`}
                                        </p>
                                    </td>
                                    <td>
                                        <p style={{ width: "100px", overflowWrap: "break-word" }}>
                                            {`${record.country}`}
                                        </p>
                                    </td>
                                    <td>
                                        <p style={{ width: "100px", overflowWrap: "break-word" }}>
                                            {`${record.department}`}
                                        </p>
                                    </td>
                                    <td>
                                        <p style={{ width: "100px", overflowWrap: "break-word" }}>
                                            {`${record.city}`}
                                        </p>
                                    </td>
                                    <td>
                                        <p style={{ width: "100px", overflowWrap: "break-word" }}>
                                            {`${record.institutionType}`}
                                        </p>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            );
        } else if (destination === '6') {
            return (
                <table className="striped" id="recordResults">
                    <thead>
                        <tr>
                            <th>Num</th>
                            <th>Nombre</th>
                            <th>Email</th>
                            <th>Creación</th>
                            <th>Celular</th>
                            <th>Teléfono</th>
                            <th>País</th>
                            <th>Departamento</th>
                            <th>Ciudad</th>
                            <th>Campaña</th>
                        </tr>
                    </thead>
                    <tbody>
                        {records.map((record, index) => {
                            return (
                                <tr key={record._id}>
                                    <td>{index + 1}</td>
                                    <td>
                                        <p style={{ width: "100px", overflowWrap: "break-word" }}>
                                            {`${record.name} - ${record.surname}`}
                                        </p>
                                    </td>
                                    <td>
                                        <p style={{ width: "200px", overflowWrap: "break-word" }}>
                                            {`${record.email}`}
                                        </p>
                                    </td>
                                    <td>
                                        <p style={{ width: "200px", overflowWrap: "break-word" }}>
                                            {`${record.created}`}
                                        </p>
                                    </td>
                                    <td>
                                        <p style={{ width: "100px", overflowWrap: "break-word" }}>
                                            {`${record.cellphone}`}
                                        </p>
                                    </td>
                                    <td>
                                        <p style={{ width: "100px", overflowWrap: "break-word" }}>
                                            {`${record.phone}`}
                                        </p>
                                    </td>
                                    <td>
                                        <p style={{ width: "100px", overflowWrap: "break-word" }}>
                                            {`${record.country}`}
                                        </p>
                                    </td>
                                    <td>
                                        <p style={{ width: "100px", overflowWrap: "break-word" }}>
                                            {`${record.department}`}
                                        </p>
                                    </td>
                                    <td>
                                        <p style={{ width: "100px", overflowWrap: "break-word" }}>
                                            {`${record.city}`}
                                        </p>
                                    </td>
                                    <td>
                                        <p style={{ width: "100px", overflowWrap: "break-word" }}>
                                            {`${record.campaignName}`}
                                        </p>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            );
        }
    }

    return (
        <div className="page">
            <div className="container">
                {error && (
                    <ErrorNotice message={error} clearError={() => setError(undefined)} />
                )}
                <form onSubmit={getRecords}>
                    <div className="row">
                        <div className="col s12">
                            Fecha de inicio:
                            <br></br>
                            <input type="datetime-local" id="date1" name="date1"
                                onChange={(e) => setDate1(e.target.value)}
                                value={date1}
                            />
                            <br></br>
                            <br></br>
                            Fecha de Finalización:
                            <br></br>
                            <input type="datetime-local" id="date2" name="date2"
                                onChange={(e) => setDate2(e.target.value)}
                                value={date2}
                            />
                            <br></br>
                            <br></br>
                            <label htmlFor="plans">Plan: </label>
                            <select name="plans" id="plans" onChange={(e) => setDestination(e.target.value)} defaultValue="">
                                <option value="" disabled>--Seleccione un plan--</option>
                                <option value="4">Estudiantes</option>
                                <option value="5">Profesores</option>
                                <option value="6">Landings de Suscripción</option>
                            </select>
                            <br></br>
                            <button type="submit" style={{ margin: "4px" }} name="action">
                                <i className="material-icons center notranslate">search</i>
                            </button>
                            <TableToExcel
                                id="exportButton"
                                table="recordResults"
                                filename="Reporte Formularios"
                                sheet="Pagina 1"
                                buttonText={<i className="material-icons center notranslate">cloud_download</i>}
                            />
                        </div>
                    </div>
                </form>
                <br></br>
            </div>
            {
                records && records.length && destination ? getTable(destination, records) : <p>No se encontraron resultados con los datos seleccionados</p>
            }
        </div>
    );
}