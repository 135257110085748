import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext from "../../context/UserContext";

export default function AuthOptions() {
    const { userData, setUserData } = useContext(UserContext);
    const history = useNavigate();

    const forms = () => {
        history('/admin/form');
    };
    const register = () => {
        history('/admin/register');
    };
    
    const login = () => history('/admin/login');
    const logout = () => {
        setUserData({
            token: undefined,
            user: undefined,
        });
        localStorage.setItem("auth-token", "");
        history('/admin/login');
    };

    return (
        <nav className="auth-options">
            {
                userData.user ? (
                    <>
                        <button onClick={forms}>Formularios</button>
                        <button onClick={register}>Usuarios</button>                        
                        <button onClick={logout}>Log out</button>
                    </>
                ):(
                    <>
                        <button onClick={login}>Log in</button>
                    </>
                )
            }
        </nav>
    )
}
